import React, { Component } from 'react';
import './EditText.css';
import '../common/Common.css';
import { ENTER_KEYCODE } from '../common/Constants';
import { Link } from 'react-router-dom';

/**
 * Style Props
 * - fontSize: (String) font size in any unit | ex "1.15rem", "14px"
 * - marginBottom: (String) bottom margin in any unit | ex "16px", "0"  
 * - paddingLeft: (String) padding left in any unit | ex "16px", "5%"
 * - paddingRight: (String) padding right in any unit | ex "16px", "0"
 * 
 * Non Style Props
 * - autoFocus: (boolean) whether or not the input field should auto focus when rendered
 * - placeHolder: (String) the placeholder text for the input field
 */
class EditText extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            savedText: '',
            style: {},
            routerLinkTo: null,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.inputOnClick = this.inputOnClick.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { paddingLeft, paddingRight, fontSize, marginBottom, marginTop } = this.props;
        let style = {
            paddingLeft: paddingLeft, 
            paddingRight: paddingRight,
            width: `calc(100% - ${paddingLeft})`,
            fontSize: fontSize,
            marginBottom: marginBottom,
            marginTop: marginTop,
        }

        let routerLinkTo = this.props.routerLinkTo;

        this.setState({
            style: style,
            routerLinkTo: routerLinkTo,
        });
    }

    handleKeyDown = (event) => {
        if (event.keyCode === ENTER_KEYCODE) {
            this.inputRef.current.blur();

            if (this.state.savedText !== '') {
                if (this.props.exerciseKeyDownHandler != null) {
                    this.props.exerciseKeyDownHandler();
                }

                if (this.props.workoutKeyDownHandler != null) {
                    this.props.workoutKeyDownHandler();
                }

                if (this.props.weekKeyDownHandler != null) {
                    this.props.weekKeyDownHandler();
                }

                if (this.props.routineKeyDownHandler != null) {
                    this.props.routineKeyDownHandler();
                }
            }
        }
    }

    handleChange(event) {
        let routerLinkTo = this.state.routerLinkTo;
        if (routerLinkTo != null) {
            routerLinkTo.state.exerciseTitle = event.target.value;
            this.setState({
                savedText: event.target.value,
                routerLinkTo: routerLinkTo,
            });
        } else {
            this.setState({
                savedText: event.target.value,
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    inputOnClick() {
        if (this.props.onClickEnabled != null && !this.props.onClickEnabled) {
            this.inputRef.current.blur();
        }
    }

    inputOnBlur() {
        if (this.state.savedText === '') {
            //If you haven't entered a name, we notify that we should delete the exercise
            if (this.props.exerciseCallback != null) {
                this.props.exerciseCallback();
            }
            
            //If you haven't entered a name, we notify that we should delete the workout
            if (this.props.workoutCallback != null) {
                this.props.workoutCallback();
            }
        }

    }

    renderInitialInput() {
        if (this.props.autoFocus && this.state.routerLinkTo != null && this.state.savedText) {
            return <Link
                key={this.state.routerLinkTo.state.exerciseId}
                to={this.state.routerLinkTo}
                >
                    <form className={this.props.label ? 'avecLabel' : ''} onSubmit={this.handleSubmit}>
                        {this.props.label ? <label>{this.props.label}</label> : null} 
                        <input type="text" 
                            style={this.state.style} 
                            ref={this.inputRef} 
                            autoFocus
                            className='editText' 
                            onKeyDown={this.handleKeyDown} 
                            value={this.state.savedText} 
                            placeholder={this.props.placeholder} 
                            onChange={this.handleChange} 
                            onClick={this.inputOnClick} 
                            onBlur={this.inputOnBlur}
                        />
                    </form>
            </Link>
                
        } else if (this.props.autoFocus) {
            return <form className={this.props.label ? 'avecLabel' : ''} onSubmit={this.handleSubmit}>
                        {this.props.label ? <label>{this.props.label}</label> : null}
                        <input type="text" 
                            style={this.state.style} 
                            ref={this.inputRef} 
                            autoFocus
                            className='editText' 
                            onKeyDown={this.handleKeyDown} 
                            value={this.state.savedText} 
                            placeholder={this.props.placeholder} 
                            onChange={this.handleChange} 
                            onClick={this.inputOnClick} 
                            onBlur={this.inputOnBlur}
                        />
                    </form>
        } else {
            return <form className={this.props.label ? 'avecLabel' : ''} onSubmit={this.handleSubmit}>
                        {this.props.label ? <label>{this.props.label}</label> : null}
                        <input type="text" 
                            style={this.state.style} 
                            ref={this.inputRef} 
                            className='editText' 
                            onKeyDown={this.handleKeyDown} 
                            value={this.state.savedText} 
                            placeholder={this.props.placeholder} 
                            onChange={this.handleChange}
                            onClick={this.inputOnClick} 
                            onBlur={this.inputOnBlur}
                        />
                    </form>
        }
    }

    render() { 
        return this.renderInitialInput();
        // return ( 
        //         {this.renderInitialInput()}
        // );
    }
}
 
export default EditText;