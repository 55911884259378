import React, { Component } from 'react';
import './Exercise.css'
import EditText from '../components/EditText';
import { DEFAULT_PADDING, FONT_SIZE_EXERCISE } from '../common/Constants';
import { withRouter } from 'react-router-dom';

class Exercise extends Component {
    constructor(props) {
        super(props);
        this.state = { }

        this.exerciseCallback = this.exerciseCallback.bind(this);
        this.exerciseKeyDownHandler = this.exerciseKeyDownHandler.bind(this);
        this.editTextRef = React.createRef();
    }

    exerciseCallback() {
        this.props.exerciseCallback(this.props.exercise);
    }

    exerciseKeyDownHandler() {
        this.props.exerciseKeyDownHandler();
    }

    render() { 
        const { location } = this.props;
        let routerLinkTo = {
                            pathname: `/routine/exercise/${this.props.exercise.exerciseId}`,
                            // This is the trick! This link sets
                            // the `background` in location state.
                            state: { 
                                background: location,
                                exerciseTitle: this.props.exercise.exerciseTitle,
                                exerciseId: this.props.exercise.exerciseId
                            }
                        }
        return ( 
            <div className='exercise' >
                {/* <Link
                key={this.props.exercise.exerciseId}
                to={{
                    pathname: `/routine/exercise/${this.props.exercise.exerciseId}`,
                    // This is the trick! This link sets
                    // the `background` in location state.
                    state: { 
                        background: location,
                        exerciseTitle: this.props.exercise.exerciseTitle
                    }
                }}
                > */}
                
                <EditText 
                    ref={this.editTextRef}
                    autoFocus={true} 
                    placeholder={this.props.exercise.exerciseTitle}
                    paddingLeft={DEFAULT_PADDING}
                    paddingRight="0"
                    fontSize={FONT_SIZE_EXERCISE}
                    onClickEnabled={false}
                    exerciseCallback={this.exerciseCallback}
                    routerLinkTo={routerLinkTo}
                    exerciseKeyDownHandler={this.exerciseKeyDownHandler}
                    />

                {/* </Link> */}
            </div>
         );
    }
}
 
export default withRouter(Exercise);

// function Exercise(props) {
//     let location = useLocation();


//     return ( 
//         <div className='exercise' >
//             <Link
//                 key={props.exercise.exerciseTitle}
//                 to={{
//                     pathname: `/routine/exercise/${props.exercise.exerciseTitle}`,
//                     // This is the trick! This link sets
//                     // the `background` in location state.
//                     state: { background: location }
//                 }}
//                 >
                
//                 <EditText 
//                     autoFocus={true} 
//                     placeholder={props.exercise.exerciseTitle}
//                     paddingLeft={DEFAULT_PADDING}
//                     paddingRight="0"
//                     fontSize={FONT_SIZE_EXERCISE}
//                     />

//                 </Link>
//         </div>
//     );
// }
 
// export default Exercise;