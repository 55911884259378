import React, { Component } from 'react';
import './Week.css';
import '../common/Common.css'
import { ADD_WORKOUT, FONT_SIZE_WEEK, ADD_ANOTHER_WORKOUT } from '../common/Constants';
import Workout from '../workout/Workout';
import DeleteIcon from '../assets/delete_icon.svg';
import EditText from '../components/EditText';

class Week extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            workouts: [],
        }

        this.renderWorkouts = this.renderWorkouts.bind(this);
        this.addWorkout = this.addWorkout.bind(this);
        this.removeWorkout = this.removeWorkout.bind(this);
        this.workoutCallback = this.workoutCallback.bind(this);
        this.removeWeek = this.removeWeek.bind(this);
        this.workoutKeyDownHandler = this.workoutKeyDownHandler.bind(this);
        this.weekKeyDownHandler = this.weekKeyDownHandler.bind(this);
    }

    renderWorkouts() {
        let workouts = this.state.workouts;
        let workoutDisplay = [];

        for (let i = 0; i < workouts.length; i++) {
            workoutDisplay.push(
                <Workout key={i} workout={workouts[i]} workoutCallback={this.workoutCallback} workoutKeyDownHandler={this.workoutKeyDownHandler}/>
            );
        }

        return workoutDisplay;
    }

    workoutKeyDownHandler() {
        this.addWorkout();
    }

    weekKeyDownHandler() {
        this.addWorkout();
    }

    addWorkout() {
        let workouts = this.state.workouts;
        let count = workouts.length;
        let newWorkout = {
            workoutTitle: `Workout ${count + 1}`,
            exercises: [],
        }
        workouts.push(newWorkout);
        this.setState({
            workouts: workouts,
        });
    }

    workoutCallback(workout) {
        this.removeWorkout(workout);
    }

    removeWorkout(workout) {
        let workouts = this.state.workouts;

        let index = workouts.indexOf(workout);
        if (index > -1) {
            workouts.splice(index, 1);
            this.setState({
                workouts: workouts,
            });
        }
    }

    removeWeek() {
        this.props.removeWeek(this.props.week);
    }

    render() { 
        let workouts = this.state.workouts;

        return (
            <div className='week'>
                <div className='weekHeader'>
                    <EditText 
                        autoFocus={true} 
                        placeholder={this.props.week.weekTitle}
                        fontSize={FONT_SIZE_WEEK}
                        marginBottom="0"
                        weekKeyDownHandler={this.weekKeyDownHandler}
                        />
                    <button className='iconButton rightAlign' onClick={this.removeWeek}>
                        <img src={DeleteIcon} alt='Delete'/>
                    </button>
                </div>
                <div className='workouts'>
                    {this.renderWorkouts()}
                    <button className='buttonAddWorkout' onClick={this.addWorkout}>{workouts.length > 0 ? ADD_ANOTHER_WORKOUT : ADD_WORKOUT}</button>
                </div>
            </div>
        );
    }
}
 
export default Week;