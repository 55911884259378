import React, { Component } from 'react';
import './Workout.css';
import Exercise from '../workout/Exercise';
import { ADD_EXERCISE, ADD_ANOTHER_EXERCISE , DEFAULT_PADDING, FONT_SIZE_WORKOUT } from '../common/Constants';
import EditText from '../components/EditText';
import { v4 as uuidv4 } from 'uuid';

class Workout extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            exercises: []
        }

        this.addExercise = this.addExercise.bind(this);
        this.renderExercises = this.renderExercises.bind(this);
        this.exerciseCallback = this.exerciseCallback.bind(this);
        this.removeExercise = this.removeExercise.bind(this);
        this.workoutCallback = this.workoutCallback.bind(this);
        this.exerciseKeyDownHandler = this.exerciseKeyDownHandler.bind(this);
        this.workoutKeyDownHandler = this.workoutKeyDownHandler.bind(this);
    }

    renderExercises() {
        let exercises = this.state.exercises;
        let exerciseDisplay = [];

        for (let i = 0; i < exercises.length; i++) {
            exerciseDisplay.push(
                <Exercise key={i} exercise={exercises[i]} exerciseCallback={this.exerciseCallback} exerciseKeyDownHandler={this.exerciseKeyDownHandler}/>
            );
        }

        return exerciseDisplay;
    }

    //Used to delete the exercise if we don't name it
    exerciseCallback(exercise) {
        this.removeExercise(exercise);
    }

    exerciseKeyDownHandler() {
        this.addExercise();
    }

    workoutKeyDownHandler() {
        this.props.workoutKeyDownHandler();
    }

    removeExercise(exercise) {
        let exercises = this.state.exercises;

        let index = exercises.indexOf(exercise);
        if (index > -1) {
            exercises.splice(index, 1);
            this.setState({
                exercises: exercises,
            });
        }
    }

    addExercise() {
        let exercises = this.state.exercises;
        let count = exercises.length;
        let newExercise = {
            exerciseId: uuidv4(),
            exerciseTitle: `Exercise ${count + 1}`,
            sets: 0,
            reps: 0,
            weight: [],
        }
        exercises.push(newExercise);
        this.setState({
            exercises: exercises,
        });
    }

    workoutCallback() {
        if (this.state.exercises.length === 0) {
            this.props.workoutCallback(this.props.workout);
        }
    }

    render() { 
        let exercises = this.state.exercises;
        
        return ( 
            <div className='workout'>
                <EditText 
                    autoFocus={true} 
                    placeholder={this.props.workout.workoutTitle}
                    paddingLeft={DEFAULT_PADDING}
                    fontSize={FONT_SIZE_WORKOUT}
                    workoutCallback={this.workoutCallback}
                    workoutKeyDownHandler={this.workoutKeyDownHandler}
                    />
                <div className='exercises'>
                    {this.renderExercises()}
                </div>
                <div className='workoutFooter'>
                    <p className='buttonAddExercise' onClick={this.addExercise}>{exercises.length > 0 ? ADD_ANOTHER_EXERCISE : ADD_EXERCISE}</p>
                </div>
            </div>
        );
    }
}
 
export default Workout;