import React, { Component } from 'react';
import './Main.css'
import '../common/Components.css'
import '../common/Common.css'
import MithrilLogoInverted from '../assets/mithril_logo_primary_foreground.svg';
import { Link } from 'react-router-dom';
import Routine from './Routine';
import { APP_TITLE, LOGOUT_BUTTON } from '../common/Constants';

class Main extends Component {
    state = {}

    render() { 
        return ( 
            <div id='main'>
                <header className="navigationBar">
                    <img id='navBarLogo' src={MithrilLogoInverted} alt='Mithril' />
                    <p id='navBarTitle'>{APP_TITLE}</p>
                    <Link className='buttonNavBar rightAlign' to="/">{LOGOUT_BUTTON}</Link>
                </header>
                <Routine/>
            </div>
        );
    }
}
 
export default Main;