import './App.css';
import { 
    Route, 
    Switch,
    useLocation,
} from 'react-router-dom';
import React from 'react';
import Login from './login/Login';
import Main from './routine/Main';
import ExerciseFullScreen from './workout/ExerciseFullScreen';

function App() {
    let location = useLocation();
    let background = location.state && location.state.background;

    return (
        <React.Fragment>
            <Switch location={background || location}>
                <Route exact path="/" component={Login} />
                <Route path="/routine" component={Main} />
            </Switch>

            {/* Show the modal when a background page is set */}
            {background && <Route path="/routine/exercise/:name" children={ <ExerciseFullScreen /> } />}
        </React.Fragment>
    );
}

export default App;
