import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Login extends Component {

    login() {
        console.log(`You've clicked the login page, lets go to the Routine page`);
    }

    render() { 
        return ( 
            <Link to="/routine">Create Routine</Link>
        );
    }
}
 
export default Login;