import React, { Component } from 'react';
import Week from './Week'
import './Routine.css'
import '../common/Components.css'
import { ADD_WEEK, ADD_ANOTHER_WEEK, DEFAULT_ROUTINE_NAME, FONT_SIZE_ROUTINE } from '../common/Constants'
import EditText from '../components/EditText';

class Routine extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            weeks: [],
        }

        this.renderWeeks = this.renderWeeks.bind(this);
        this.addWeek = this.addWeek.bind(this);
        this.removeWeek = this.removeWeek.bind(this);
        this.routineKeyDownHandler = this.routineKeyDownHandler.bind(this);
    }

    renderWeeks() {
        let weeks = this.state.weeks;
        let weekDisplay = [];

        for (let i = 0; i < weeks.length; i++) {
            weekDisplay.push(
                <Week key={i} week={weeks[i]} removeWeek={this.removeWeek}/>
            );
        }

        return weekDisplay;
    }

    routineKeyDownHandler() {
        this.addWeek();
    }

    addWeek() {
        let weeks = this.state.weeks;
        let count = weeks.length;
        let newWeek = {
            weekTitle: `Week ${count + 1}`,
            workouts: [],
        }
        weeks.push(newWeek);
        this.setState({
            weeks: weeks,
        });
    }

    //Removes the passed in week
    removeWeek(week) {
        let weeks = this.state.weeks;

        let index = weeks.indexOf(week);
        if (index > -1) {
            weeks.splice(index, 1);
        }

        this.setState({
            weeks: weeks,
        });
    }

    render() { 
        let weeks = this.state.weeks;

        return ( 
            <div id='program'>
                <EditText 
                    autoFocus={true} 
                    placeholder={DEFAULT_ROUTINE_NAME}
                    routineKeyDownHandler={this.routineKeyDownHandler}
                    fontSize={FONT_SIZE_ROUTINE}
                    />
                {this.renderWeeks()}
                <p className='buttonAdd' id='bottomPadding' onClick={this.addWeek}>{weeks.length > 0 ? ADD_ANOTHER_WEEK : ADD_WEEK}</p>
            </div>
        );
    }
}
 
export default Routine;