import { 
    useHistory,
    useLocation
} from 'react-router-dom';
import { DEFAULT_FONT_SIZE, DEFAULT_PLACEHOLDER, REPS, SETS, WEIGHT } from '../common/Constants';
import EditText from '../components/EditText';
import './ExerciseFullScreen.css';

function ExerciseFullScreen() {
    let history = useHistory();
    let location = useLocation();

    function parentClick(e) {
        e.stopPropagation();
        history.goBack();
    };

    function childClick(e) {
        e.stopPropagation();
    }

    return (
        <div className='exerciseFullScreen' onClick={parentClick} >
            <div className='selectedExercise' onClick={childClick}>
                <p className='selectedExerciseTitle'>{location.state.exerciseTitle}</p>
                <EditText 
                    label={SETS}
                    placeholder={DEFAULT_PLACEHOLDER}
                    fontSize={DEFAULT_FONT_SIZE}
                    marginBotton="0"
                    marginTop="0"
                    autoFocus={true}
                    />
                <EditText 
                    label={REPS}
                    placeholder={DEFAULT_PLACEHOLDER}
                    fontSize={DEFAULT_FONT_SIZE}
                    marginBotton="0"
                    marginTop="0"
                    />
                <EditText 
                    label={WEIGHT}
                    placeholder={DEFAULT_PLACEHOLDER}
                    fontSize={DEFAULT_FONT_SIZE}
                    marginBotton="0"
                    marginTop="0"
                    />
            </div>
        </div>
    );
}

export default ExerciseFullScreen;
